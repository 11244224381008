<template>
  <div class="content palm-bg">
    <div class="row" style="background: rgba(45, 91, 88, 0.4)">
      <section class="text-center col-lg-8 col-sm-12">
        <div
          class="centered text-center"
          style="width: 100%"
          :style="{
            background: `linear-gradient(${this.secondaryColor}, black)`,
          }"
        >
          <HeadImage
            :image="mainImage"
            align="text-center"
            class="centered"
            title="TULUM PARTY GUIDE"
            caption="Tulum Decoded"
          ></HeadImage>

          <p>
            The Tulum Party Guide is where you will find everything related to
            the House and Techno Musical Nightlife and Culture of Tulum. A
            gameplay for every player in the Scene: from how to reserve a table
            and the detailed menus of prominent restaurants, to all you need to
            know about the ins and outs of Tulum, its
            <router-link :to="{ path: '/tulum-clubs' }">Clubs</router-link>
            , House Parties,
            <router-link :to="{ path: '/brands' }"
              >Production brands</router-link
            >
            , and the famous
            <router-link :to="{ path: '/tulum-new-year-festival-2025' }"
              >high-season NYE Festival in Tulum.</router-link
            >
          </p>
          <br />
        </div>

        <hr />

        <section class="text-justify">
          <h3 class="section-header">ENTRIES</h3>
        </section>

        <div id="articles" class="row">
          <div
            v-for="(article, idx) in articles"
            class="col-sm-12 card card-animation-on-hover-no-bg"
            :key="idx"
          >
            <div
              class="centered"
              style="width: 100%"
              :style="{
                background: `linear-gradient(${secondaryColor}, black)`,
              }"
            >
              <router-link :to="{ path: '/article/' + article.slug }">
                <img
                  v-lazy="
                    article.metadata.cover.imgix_url +
                    '?w=315&h=200&fit=fillmax&fill=blur&auto=format'
                  "
                  style="
                    margin-top: -10px;
                    opacity: 0.8;
                    z-index: 12;
                    box-shadow: 0 10px 15px 0 rgba(0 0 0, 0.6);
                    border-radius: 0;
                  "
                  alt=" TULUM PARTY BLOG"
                  width="315px"
                  height="200px"
                  class="d-inline-xs d-sm-none"
                />

                <img
                  v-lazy="
                    article.metadata.cover.imgix_url +
                    '?h=300&w=615&fit=fillmax&fill=blur&auto=format'
                  "
                  style="
                    margin-top: -10px;
                    opacity: 0.8;
                    z-index: 12;
                    box-shadow: 0px 10px 15px 0px rgba(0 0 0, 0.6);
                    border-radius: 0;
                  "
                  alt=" TULUM PARTY BLOG"
                  width="615px"
                  class="d-none d-sm-inline"
                  height="300px"
                />
                <div class="text-left">
                  <h3
                    class="section-header text-left"
                    style="text-transform: capitalize; font-size: 1.1rem"
                  >
                    {{ article.title }}
                  </h3>
                  <p
                    style="
                      padding: 10px;
                      margin-top: -50px;
                      font-size: 11px;
                      text-transform: capitalize;
                      font-weight: lighter;
                    "
                  >
                    {{ article.metadata.caption }}
                  </p>
                  <div class="card" style="margin-top: 10px">
                    <small
                      ><i
                        class="tim-icons icon-calendar-60"
                        :style="{
                          color: secondaryColor,
                          borderRadius: '100px',
                          padding: '5px',
                        }"
                      ></i>
                      {{ new Date(article.created_at).toDateString() }}</small
                    >
                    <small
                      ><i
                        class="tim-icons icon-pencil"
                        :style="{
                          color: secondaryColor,
                          borderRadius: '100px',
                          padding: '5px',
                        }"
                      ></i>
                      {{ new Date(article.published_at).toDateString() }}
                    </small>
                  </div>
                  <h5 class="section-header">
                    <i
                      class="tim-icons icon-light-3"
                      style="font-size: 12px"
                      :style="{
                        color: secondaryColor,
                        border: `2px solid ${secondaryColor}`,
                        borderRadius: '100px',
                        padding: '5px',
                      }"
                    ></i>
                    SUMMARY
                  </h5>
                  <div
                    v-html="article.metadata.summary"
                    id="article-summary"
                  ></div>

                  <br />
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <hr />
        <p>
          Showing {{ val > 1 ? pageSize + 1 : 1 }} -
          {{ articles ? articles.length + (val > 1 ? pageSize : 0) : 0 }} of
          {{ total }} ARTICLE |
          <a
            style="display: inline-block"
            class="btn btn-link"
            @click="showAll"
            v-if="!showingAll"
            >Show all ({{ total }})</a
          >
        </p>

        <BasePagination
          type="primary"
          :per-page="pageSize"
          :total="total"
          :value="val"
          v-if="!showingAll"
          :nextPage="nextPage"
          :prevPage="prevPage"
          :changePage="changePage"
        ></BasePagination>

        <SmartMenu
          :links="links"
          :logo="this.mainImage"
          ctaText="DROP US A LINE"
          top-section-name="TULUM.PARTY"
        >
          <div class="row">
            <div class="col-sm-12 text-center">
              <p><strong>Feeling Lost?</strong></p>
              <hr />
              <p>
                We Are Your Single Contact for All Clubs & Events:
                <strong>Simplify your planning!</strong>
              </p>
              <hr />
            </div>
          </div>
        </SmartMenu>

        <DynamicArticles></DynamicArticles>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu
          :links="links"
          :logo="astroImg"
          top-section-name="TALK TO US"
          cta-text="DROP US A LINE"
          :primary-color="primaryColor"
        >
          <p>
            All Clubs & Events <br />Information & Reservations <br /><br />
          </p>
        </SmartMenu>
        <DynamicArticles></DynamicArticles>

      </aside>
    </div>
  </div>
</template>

<script>
import BasePagination from '../../components/BasePagination.vue';
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import HeadImage from '@/components/HeadImage/HeadImage';
import DynamicArticles from '@/components/DynamicArticles';

export default {
  name: 'Blog',
  metaInfo() {
    return {
      title: `Tulum Party Guide. Experience Tulum's Vibrant Scene: A Comprehensive Guide`,
      meta: [
        {
          name: 'description',
          content: `Plan your unforgettable Tulum adventure with our comprehensive guide.`,
        },
        {
          name: 'keywords',
          content: `Tulum Party Guide Blog, Articles, Nightlife, Tulum Scene, Travel Recommendations`,
        },
      ],
    };
  },
  props: ['slug'],
  data: () => {
    return {
      table: [],
      val: undefined,
      primaryColor: '#53453b',
      secondaryColor: '#776767',
      mainImage:
        'https://imgix.cosmicjs.com/5a39f560-c8f3-11ee-9ce5-59949019255e-day-zero-main-stage.jpg',
      showingAll: false,
      showPalms: false,
      pageSize: 30,
      page: 0,
      showModal: false,
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      // astroimage: 'https://imgix.cosmicjs.com/fdb719e0-e8fd-11ef-b333-e101bec29f3d-e82abebd3cb5882d4423c7666a57660b.webp',
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
    };
  },
  components: {
    DynamicArticles,
    HeadImage,
    BasePagination,
    SmartMenu,
  },
  created: function () {
    // this.$store.dispatch('getArtists');
    this.$store.dispatch('getArticles', { start: 0, limit: this.pageSize });
    this.$store.dispatch('getLinks', { slug: 'blog' });
  },
  methods: {
    changePage: function (page) {
      this.page = page;
      this.val = this.page;
      this.$store.dispatch('getArticles', {
        start: this.page * this.pageSize,
        limit: this.pageSize,
      });
    },
    nextPage: function () {
      this.page++;
      this.val = this.page;
      this.$store.dispatch('getArticles', {
        start: this.page * this.pageSize,
        limit: this.pageSize,
      });
    },
    showAll: function () {
      this.$store.dispatch('getArticles', { start: 0, limit: this.total });
      this.showingAll = true;
    },
    prevPage: function () {
      this.page--;
      this.val = this.page;

      this.$store.dispatch('getArticles', {
        start: this.page * 10,
        limit: this.pageSize,
      });
    },
  },
  computed: {
    total: {
      get() {
        return this.$store.state.total;
      },
    },
    articles: {
      get() {
        return this.$store.state.articles;
      },
    },
    links: {
      get() {
        return this.$store.state.links || [];
      },
    },
  },
};
</script>
